body {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  background-color: #000000;
  /* min-height: 100vh; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  color: rgba(255, 228, 140, 0.521);
  color: rgb(255, 255, 255);
  font-family: 'New Rocker';
	margin: 0 auto;
	text-align: center;
  /* font-family: 'Raleway Dots', cursive; */
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif; */
  /* font-weight: 400; */
  /* font-style: normal; */
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

h1 {
  font-weight: 400;
  font-style: normal;
}

a {
	color: inherit;
	text-decoration: none;
}

/* button:focus {
  outline: none;
}

button:hover,
button:focus {
    background: rgba(255, 198, 75, 0);
    transition: transform .1s ease-in-out;
    transform: scale(1.1);
}

button:focus {
    outline: none;
    transition: transform .1s ease-in-out;
    transform: scale(1.1);
}

button:active {
    transform: scale(0.99);
} */

/* Link {
	color: inherit;
	text-decoration: none;
} */

/* link:hover, link:focus {
  outline: none;
} */